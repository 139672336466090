import React from "react";
import { useSelector } from "react-redux";

import OrganizationCode from "./Pages/OrganizationCode/OrganizationCode";
import CardType from "./Pages/CardType/CardType";
import TypeIssue from "./Pages/TypeIssue/TypeIssue";

import PersonalDetails from "./Pages/PersonalDetails/PersonalDetails";
import AddressInformation from "./Pages/AddressInformation/AddressInformation";

import DetailsEmployment from "./Pages/DetailsEmployment/DetailsEmployment";

import ForeignNational from "./Pages/ForeignNational/ForeignNational";

import ApplicantDeclaration from "./Pages/ApplicantsDeclaration/ApplicantsDeclaration";
import ConfirmApplication from "./Pages/ConfirmApplication/ConfirmApplication";

// custom style
import "./newApplication.css";

function NewApplication() {
  const myCurrentStates = useSelector(
    (state) => state.newApplicationStateTracker
  );

  return (
    <div className="content-adjuster">
      <div className="inner-content">
        {/* =========================== Part A =========================== */}
        {myCurrentStates.stepCount === 1 && <OrganizationCode />}
        {myCurrentStates.stepCount === 2 && <CardType />}
        {myCurrentStates.stepCount === 3 && <TypeIssue />}

        {/* =========================== Part B =========================== */}
        {myCurrentStates.stepCount === 4 && <PersonalDetails />}
        {myCurrentStates.stepCount === 5 && <AddressInformation />}

        {/* =========================== Part C =========================== */}
        {myCurrentStates.stepCount === 6 && <DetailsEmployment />}

        {/* =========================== Part D =========================== */}
        {/* if cardType is ASP/CMAP fill up the form */}
        {myCurrentStates.stepCount === 7 && <ForeignNational />}

        {/* =========================== Part D or E =========================== */}
        {/* if cardType is NIAC/NAP D else E */}
        {myCurrentStates.stepCount === 8 && <ApplicantDeclaration />}

        {/* =========================== Submit =========================== */}
        {myCurrentStates.stepCount === 9 && <ConfirmApplication />}
      </div>
    </div>
  );
}

export default NewApplication;
